
import { hideDelete } from '@/lib/utils/hideDelete';
import { idInJson } from '@/lib/utils/idInJson';
import { isBlocked } from '@/lib/utils/isBlocked';
import React, { useEffect, useRef, useState } from 'react';
import { ChatMessageOptions } from '../profile/chat/chat-message-options';
import AuthorCredit from '../templateux/user/author-credit';
import { blockHandler } from "@/lib/utils/moderation/blockHandler";
import { ignoreHandler } from "@/lib/utils/moderation/ignoreHandler";
import { JSONpreparrayforurl } from '@/lib/utils/JSONpreparrayforurl';
import ChatLikesButton from '../profile/chat/chat-likes-button';
import SvgJsx from '../templateux/svg/svg-jsx';
import { likeHandler, removeHandler, reportHandler, saveHandler } from './chathandlers';
import ChatReportsButton from '../profile/chat/chat-reports-button';
import ChatSavesButton from '../profile/chat/chat-saves-button';
import { chatauthor } from './chatauthor';
import { hasData } from '@/lib/utils/hasData';
import { calculateAspectRatioFit } from '@/lib/utils/calculateAspectRatioFit';
import { linkify } from '@/lib/utils/linkify';
import ChatLikesButtonUnder from '../profile/chat/chat-likes-button-under';
import AvatarFlairClean from '../templateux/user/avatar-flair-clean';
import ChatLikesButtonAction from '../profile/chat/chat-likes-button-action';
import { useConfirm } from '@/lib/utils/hooks/useConfirm';
import ChatOptionButton from '../profile/chat/chat-option-button';
import { config } from '@/lib/config';

const MessageNested = ({
  
    index
   ,type
   ,chat
   ,profiledata
   ,userdata
   ,show_header
   ,show_footer
   ,isAdmin
   ,openModal
   ,openModalChooser
   ,closeModal
   ,CloseButton
   ,msg
   ,set_msg
   ,session
   ,chat_emoji
   ,inputRef
   ,position
   ,time
   ,chatWidth
   ,set_commenting
 }) => {
    
    const menuRef = useRef(null);
    const { isConfirmed } = useConfirm();
    const closeMenu = () => {if (menuRef.current) menuRef.current.close()};

    // useEffect(()=>{console.log(chat)},[chat])

    const [image,set_image] = useState(hasData(chat?.image_json) ? JSON.parse(chat?.image_json) : null)
    
    useEffect(()=>{
       if (hasData(chat?.image_json)) {
        set_image(JSON.parse(chat?.image_json)) 
       }
    },[chat])

    var message_content = userdata?.bol_ignore_filters?.toString() == "1" 
                        ? (chat?.message_original) 
                        : (chat?.message)

    const modal_header = (title) => (<div className="flex items-center content-center mb-4">
                                <div className="flex-1">
                                    {title}
                                </div>
                                <div  className="flex-0 mr-2">
                                <>
                                    
                                    <ChatLikesButton 
                                        likes_json={JSONpreparrayforurl(chat?.likes_json)}
                                        chatter_id={chat?.id}
                                        my_id={userdata?.id}
                                        onClick={()=> {
                                                if (session) { 
                                                    likeHandler(
                                                    chat?.chat_id
                                                    ,chat?.id
                                                    ,userdata?.id
                                                    ,profiledata?.id)
                                                }}}
                                        likes={chat?.likes}
                                        chat_emoji={chat_emoji}
                                        openModal={openModal}
                                        session={session}
                                        iconSizeClass={`text-lg px-1 py-0.5`}
                                        closeMenu={closeMenu}
                                        show_number={true}
                                    />
                                </>
                                </div>
                                <div className="flex-0 ">
                                    <CloseButton />
                                </div>
                            </div>)

    const chat_content = (max_width,max_height) => (<>

                    {/* TEXT */}
                    {chat?.emoji?.toString() == "0" &&
                    <span
                        className={`text-ellipsis overflow-hidden font-normal`}
                        dangerouslySetInnerHTML={{
                            __html: linkify(message_content,profiledata?.user_name)
                        }}
                    >
                        {/* {linkify(message_content)} */}
                        
                    </span>
                    }

                     {/* EMOJI */}
                     {chat?.emoji?.toString() == "1" &&
                    <span
                        className={`text-ellipsis overflow-hidden text-3xl`}
                    >
                        {chat?.message_original}
                    </span>
                    }

                     {/* GIF */}
                     {chat?.emoji?.toString() == "2" &&
                     <>
                    <div
                        className={`break-all text-ellipsis overflow-hidden flex cursor-pointer`}
                        onClick={()=>
                            openModalChooser(
                                "image",
                                (<>
                                    {modal_header("Zoomed in gif")}
                                    <div className="text-center w-full flex flex-col items-center justify-center">
                                        <div className="text-center w-full flex justify-center margin-auto">
                                        <img
                                            src={image?.images?.original?.webp}
                                            alt={image?.title}
                                            width={calculateAspectRatioFit(image?.images?.original?.width,image?.images?.original?.height,800,600).width || 800}
                                            height={calculateAspectRatioFit(image?.images?.original?.width,image?.images?.original?.height,800,600).height || 600}
                                            className="shrink-0 border bg-white max-w-full max-h-full h-full  cursor-pointer"
                                            onClick={()=>closeModal()}
                                        />
                                        </div>
                                        <div className="text-center text-lg font-bold">
                                            {image.title}
                                        </div>
                                    </div>
                                </>)
                        )}
                    >
                        {hasData(image?.images?.fixed_width?.url) &&
                        <img
                            src={image?.images?.fixed_width?.url}
                            alt={image?.title}
                            width={calculateAspectRatioFit(image?.images?.fixed_width?.width,image?.images?.fixed_width?.height,max_width,max_height).width || max_width}
                            height={calculateAspectRatioFit(image?.images?.fixed_width?.width,image?.images?.fixed_width?.height,max_width,max_height).height || max_height}
                            className="shrink-0 object-fit"
                        />
                        }
                        
                    </div>
                    </>
                    }

                    {/* UPLOAD, DRAW */}
                    {["3","4"].indexOf(chat?.emoji?.toString()) > -1 &&
                     <>
                    <div
                        className={`break-all text-ellipsis overflow-hidden flex cursor-pointer`}
                        onClick={()=>
                            openModalChooser(
                                "image",
                                (<>
                                    {modal_header("Zoomed in image")}
                                    <div className="text-center w-full flex flex-col items-center justify-center">
                                        <div className="text-center w-full flex justify-center margin-auto">
                                        <img
                                            src={image?.url}
                                            alt={image?.filename}
                                            width={calculateAspectRatioFit(image?.width,image?.height,800,600).width || 800}
                                            height={calculateAspectRatioFit(image?.width,image?.height,800,600).height || 600}
                                            
                                            className="shrink-0 border bg-white max-w-full max-h-full h-full  cursor-pointer"
                                            onClick={()=>closeModal()}
                                        />
                                        </div>
                                        <div className="text-center text-lg font-bold">
                                            {image.filename} ({image?.width}x{image?.height})
                                        </div>
                                    </div>
                                   
                                </>)
                        )}
                    >
                        {hasData(image?.url) &&
                        <img
                            src={image?.url}
                            alt={image?.filename}
                            width={calculateAspectRatioFit(image?.width,image?.height,max_width,max_height).width || max_width}
                            height={calculateAspectRatioFit(image?.width,image?.height,max_width,max_height).height || max_height}
                            style={{height: calculateAspectRatioFit(image?.width,image?.height,max_width,max_height).height, 
                                    width: calculateAspectRatioFit(image?.width,image?.height,max_width,max_height).width
                                    }}
                            className="shrink-0 bg-white"
                        />
                        }
                    </div>
                    </>
                    }

                </>)

  return (<>

<>
           <div className={`flex items-top content-center px-2 group ${(show_header && index > 0) ? " border-t border-black" : ''}
           ${chat?.bol_private?.toString() == "1"
           ? `bg-[#330505] text-white border-[#330505]
               
              hover:bg-[rgb(57,11,11)]
               ` 
           : `
               bg-gray-900 text-white border-gray-700
                hover:bg-gray-800
               ` 
           } 

           ${show_header
            ? "pt-3"
            : ""}

            ${show_footer
              ? "pb-3"
              : ""}
           
           `}>
 
 
              {/* DESKTOP AVATAR COLUMN */}
             {show_header &&
             <div className={`flex-0 hidden ${chatWidth < config.chat.windowoffset.minmessagewidth ? "" : "xs:block"} mr-1`}>
               <AvatarFlairClean              
                  isPlaying={chat?.stream_status == '1'}
                  isContributor={chat?.contributor_level > 0}
                  avatar_url={chatauthor(chat,`avatar_url`)}
                  avatar_url_sfw={chatauthor(chat,`avatar_url_sfw`)}
                  bol_nsfw={chatauthor(chat,`bol_nsfw`)}
                  avatar_crdate={chatauthor(chat,`avatar_crdate`)}
                  id={chatauthor(chat,`id`)}
                  user_name={chatauthor(chat,`user_name`,profiledata?.bot_default_name)}
                  bgClass='w-8 h-8'
                  containerClass='w-9 h-9 '
                  avatarClass='w-8 h-8 border-2 border-transparent '
                  rotaryContainerClass='w-8 h-8 mr-4'
                  rotaryInternalClass='w-8 h-8'
                  sizes={{
                    width:50
                   ,height:50
                   ,quality:50
                  }}
              />
             </div>
             }
 
             {/* CHAT CONTENT */}
             <div className="flex-1
             
             
             ">
 
               {/* HEADER CONTENT - ONLY SHOW WHEN NEW */}
               {show_header &&
               <div className="flex items-center content-center">
 
                 {/* DESKTOP (!Avatar) */}
                 <div 
                  className={`flex-0 hidden ${chatWidth < config.chat.windowoffset.minmessagewidth ? "" : "xs:block"} cursor-pointer`}
                 >
                  <div
                        onClick={()=> {
                        if (type=="message") openModal(chatauthor(chat,`user_name`,profiledata?.bot_default_name),"single","users",1,"Individual User" )}
                        }
                    >
                    <AuthorCredit
                            display_data={chat}
                            isPlaying={false}
                            profiledata={profiledata}
                            show_avatar={false}
                            custom_flair={chatauthor(chat,`custom_flair`)}
                            bol_mod={chatauthor(chat,`bol_mod`)}
                            chatWidth={chatWidth}
                        />
                    </div>
                 </div>

                 <div className={`flex-1 hidden ${chatWidth < config.chat.windowoffset.minmessagewidth ? "" : "xs:block"}`}></div>
 
                 {/* MOBILE CREDITS (w Avatar) */}
                 <div 
                  className={`flex-0 block ${chatWidth < config.chat.windowoffset.minmessagewidth ? "" : "xs:hidden"} cursor-pointer`}
                >
                   <div
                        onClick={()=> {
                        if (type=="message") openModal(chat?.user_name,"single","users",1,"Individual User" )}
                        }
                    >
                    <AuthorCredit
                            display_data={chat}
                            isPlaying={false}
                            profiledata={profiledata}
                            show_avatar={true}
                            custom_flair={chatauthor(chat,`custom_flair`)}
                            bol_mod={chatauthor(chat,`bol_mod`)}
                            chatWidth={chatWidth}
                        />
                    </div>
                 </div>

                 <div className="flex-1 block xs:hidden"></div>

                 {/* ACTIONS */}
                 <div className="flex-0">
                 <div className="flex items-center content-center">    
                     {/* PRIVATE */}
                    {type=="message" && chat?.bol_private==1 &&
                    <div
                        
                        
                        className={`cursor-pointer p-0.5 px-1.5 flex items-center content-center flex-0 ml-2  rounded-full 
                        bg-red-600  text-white
                        `}
                        
                      
                    >
                        <div 
                        className="flex-0">
                        <SvgJsx 
                            type={ `fill` } 
                            icon={`lock-closed-sm` } 
                            className={` w-3 h-3 flex-0 my-auto`}
                            title={`Private message`} 
                        />
                        </div>
                        <div className="flex-1 text-3xs hidden sm:block font-bold ml-1">
                            PRIVATE
                        </div> 
                    </div>
                    }
                  </div>
                 </div>

                 <div className="flex-0 ml-2 min-w-[24px] text-right text-xs"  title={new Date(chat?.crdate)?.toString()}>
                    {/* <Timer
                        start_date={+new Date(chat?.crdate)}
                        timestamp={+new Date(chat?.timestamp)}
                        time={time}
                        initial_time={chat?.initial_time || 0}
                    /> */}
                    {new Date(chat?.crdate).toLocaleTimeString('en-us', { hour:"numeric", minute:"numeric"})}
                   
                 </div>
                
               </div>
               }
 
               {/* MESSAGE CONTENT - ALWAYS SHOW */}
               <div className="flex items-top content-top ">
                 <div className={`flex-1 markdown  ${(!show_header && chatWidth >= config.chat.windowoffset.minmessagewidth) ? 'xs:ml-10' : ''}
                 ${{
                    "1": "text-gray-200",
                    "0": "text-white",
                    "-1": "text-red-600",
                  }[type=="message"
                    ? chat?.placeholder?.toString()
                    : "0"]}
                 `}>

                  
                    <div 
                        
                        className=" group relative break-words" style={{maxWidth: chatWidth}}>
                           
                            <div className={` ${chat?.bol_filtered?.toString()=="1" ? " underline decoration-dotted decoration-red-500/50 " : "" }`}>
                                {(hideDelete(chat_content(400,200), chat?.bol_delete, chat?.user_status))}
                            </div>
                            <div className={` hidden 

                                        ${chatWidth < config.chat.windowoffset.minmessagewidth ? "" : "group-hover:xs:flex"}
                           
                                         items-center content-center absolute  
                                        top-[1px] 
                                        flex-0 
                                        text-gray-500
                                        -left-[40px]
                                        text-3xs
                                        `}
                                        title={new Date(chat?.crdate).toLocaleTimeString('en-us', {weekday:"short",month:"long",day:"numeric",year:"numeric",hour:"numeric", minute:"numeric"})}
                                        >
                                           {new Date(chat?.crdate).toLocaleTimeString('en-us', { hour:"numeric", minute:"numeric"})}
                            </div>

                            {session &&
                            <div className={` hidden group-hover:flex items-center content-center absolute  
                                        -top-[24px] 
                                        flex-0 
                                        bg-gray-600
                                        -right-[4px]
                                          rounded-md
                                          overflow-hidden
                                         shadow-md
                                        `}
                                        >
                                            
                        
                                {/* ACTIONS */}
                                <div className=" flex-1 flex items-center content-center ">
                                    <div className="flex-1"></div>
                                    <div className="flex flex-0 items-center content-center ml-1">

                                        
                                    {/* Reply */}
                                    <ChatOptionButton
                                        display={true}
                                        icon={`chat-alt-sm`}
                                        title={'Reply'}
                                           onClick={()=> {

                                                            let reply_name = chatauthor(chat,`user_name`,profiledata?.bot_default_name)
                                                            set_msg({
                                                            text: `@${reply_name} #${chat?.chat_id} `,
                                                            emoji: 0,
                                                            bol_private: chat?.bol_private == "1" ? "1" : msg.bol_private
                                                        });
                                                        inputRef.current.value=`@${reply_name} #${chat?.chat_id} `
                                                        inputRef.current.focus();
                                                        set_commenting(true);
                                                        
                                                    }}
                                        />

                                        {/* REPORTS */}
                                        <ChatReportsButton
                                            reports={chat?.reports}
                                            reports_json={JSONpreparrayforurl(chat?.reports_json)}
                                            isAdmin={isAdmin}
                                            my_id={userdata?.id}
                                            onClick={()=>reportHandler(chat?.chat_id,userdata?.id,profiledata?.id,chat?.reports > 0 ? -1 : 1,isConfirmed)}
                                            openModal={openModal}
                                            show_numbers={false}
                                        />

                                        {/* SAVES */}
                                        <ChatSavesButton
                                            saves_json={JSONpreparrayforurl(chat?.saves_json)}
                                            my_id={userdata?.id}
                                            onClick={()=>saveHandler(chat?.chat_id,userdata?.id,profiledata?.id)}
                                        />

                                         {/* Delete */}
                                        <ChatOptionButton
                                            display={(isAdmin || chat?.id == userdata?.id)}
                                            icon={`trash-sm`}
                                            title={'Delete'}
                                            onClick={()=> removeHandler(chat?.chat_id,profiledata?.id,isConfirmed,message_content,chat?.id == null ? profiledata?.bot_default_name : chat?.user_name)}
                                        />

                                        {/* LIKES */}
                                        
                                        <div className={`
                                        
                                            flex-0 

                                            `}
                                            >
                                            <ChatLikesButtonAction
                                                likes_json={JSONpreparrayforurl(chat?.likes_json)}
                                                chatter_id={chat?.id}
                                                my_id={userdata?.id}
                                                onClick={()=> {
                                                        if (session) { 
                                                            likeHandler(
                                                            chat?.chat_id
                                                            ,chat?.id
                                                            ,userdata?.id
                                                            ,profiledata?.id)
                                                        }}}
                                                likes={chat?.likes}
                                                chat_emoji={chat_emoji}
                                                openModal={openModal}
                                                session={session}
                                                closeMenu={closeMenu}
                                                show_number={false}
                                            />
                                        </div>
                                            
                                    </div>
                                    <div className="flex-0 text-gray-700">
                                    
                                    <ChatMessageOptions 
                                    
                                                chatter_id={chat?.id}
                                                chat_id={chat?.chat_id}
                                                chat_text={hideDelete(chat?.message_original, chat?.bol_delete, chat?.user_status)}
                                                chatter_name={chat?.user_name}
                                                clicker_name={userdata?.user_name}
                                                clicker_id={userdata?.id}
                                                owner_id={profiledata?.id}
                                                owner_name={profiledata?.user_name}
                                                shrink={true}
                                                removeHandler={removeHandler}
                                                blockHandler={blockHandler}
                                                ignoreHandler={ignoreHandler}
                                                handleReportAdmin={() => { } }
                                                saveHandler={saveHandler}
                                                reportHandler={reportHandler}
                                                isAdmin={isAdmin}
                                                isOwner={chat?.id == userdata?.id}
                                                isIgnored={idInJson(userdata?.ignore_json, chat?.id)}
                                                isBlocked={isBlocked(
                                                    profiledata?.block_json,
                                                    chat?.timestamp,
                                                    chat?.user_name)}
                                                openModal={openModal}
                                                likes={chat?.likes}
                                                likes_json={chat?.likes_json}
                                                saves={chat?.saves}
                                                saves_json={chat?.saves_json}
                                                reports={chat?.reports}
                                                reports_json={chat?.reports_json}
                                                crdate={chat?.crdate}
                                                message={(<>
                                                            {(hideDelete(chat_content(200,90), chat?.bol_delete, chat?.user_status))}
                                                            </>)}
                                                message_original_raw={(hideDelete(chat?.message_original_raw, chat?.bol_delete, chat?.user_status))}
                                                emoji={chat_emoji}
                                                bol_filtered={chat?.bol_filtered}
                                                position={position}
                                                type={chat?.type}
                                                likeHandler={likeHandler}
                                                session={session} 
                                        />
                                
                                    </div>
                                </div>
                        
                        
                            </div>
                            }
                    </div>
                    <div>
                        <div className={`
                            ${(parseInt(chat?.likes) > 0) 
                            ? 'block' 
                            : 'hidden'} 
                            flex-0 mt-1

                            `}
                            >
                            <ChatLikesButtonUnder
                                likes_json={JSONpreparrayforurl(chat?.likes_json)}
                                chatter_id={chat?.id}
                                my_id={userdata?.id}
                                onClick={()=> {
                                        if (session) { 
                                            likeHandler(
                                            chat?.chat_id
                                            ,chat?.id
                                            ,userdata?.id
                                            ,profiledata?.id)
                                        }}}
                                likes={chat?.likes}
                                chat_emoji={chat_emoji}
                                openModal={openModal}
                                session={session}
                                closeMenu={closeMenu}
                                show_number={true}
                            />
                        </div>
                    </div>



                 </div>
                 {(type=="message" && chat?.placeholder =="-1") && 
                 <div className="flex-0 flex text-2xs text-white bg-red-500 p-1 rounded-md">
                  undelivered!
                  </div>}
                 
                  
                
                </div>
             </div>
             
 
           </div>
         </>
  
    
  </>)
 
 
 }



export const Message = (MessageNested)