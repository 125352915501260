import LoadingRotary from "../loading/loading-rotary"
import Image from "next/image"
import { getAvatarUrl } from "@/lib/utils/getAvatarUrl"

function AvatarFlairClean({
     isPlaying=false
    ,isContributor=false
    ,containerClass='w-8 h-6'
    ,bgClass='w-6 h-6 '
    ,avatarClass='w-6 h-6 mr-2 border-2 border-transparent '
    ,rotaryContainerClass='w-[28px] h-6 mr-1'
    ,rotaryInternalClass='w-8 h-9'
    ,sizes={
         width:25
        ,height:25
        ,quality:50
    }
    ,avatar_url
    ,avatar_url_sfw
    ,bol_nsfw
    ,avatar_crdate
    ,id
    ,user_name
    ,showReminder=false
}) {
    return (<>
                            <div className={`${containerClass} relative cursor-pointer  group`}>

                                {/* EMPTY BG DIV FOR BORDER */}
                                <div
                                  className={`${bgClass} absolute flex items-center content-center rounded-full 
                                  ${isContributor 
                                    ? 'bg-blue-600 group-hover:bg-blue-500'
                                    : 'bg-transparent group-hover:bg-gray-400' }
                                  
                                  `}
                                ></div>

                                {/* ROTARY POINTER FOR CONTRIBUTORS */}
                                {!isPlaying &&
                                <div 
                                className={`${rotaryContainerClass} flex items-center content-center absolute 
                                `}>
                                  {/* <LoadingRotary 
                                    classSize={rotaryInternalClass}
                                    classAnimate=''
                                    bgColor='transparent'
                                    centerColor='transparent'
                                    pointerColor={showReminder 
                                                  ? "#ffffff"
                                                  :isContributor 
                                                    ? '#ff0000'
                                                    : 'transparent' }
                                    holeColor='transparent'
                                    dialerColor='transparent'
                                    size='64'
                                  /> */}
                                </div>
                                }

                                {/* AVATAR */}
                                <div 
                                className={`${avatarClass} absolute flex items-center content-center rounded-full 
                                        ${isPlaying
                                          ? 'animate-spin-slow'
                                          : ''}
                                `}>

                                    
                                  <Image
                                    src={getAvatarUrl(
                                        avatar_url
                                        ,avatar_url_sfw
                                        ,bol_nsfw
                                        ,avatar_crdate
                                        ,''
                                        ,id
                                    )}
                                    alt={user_name}
                                    
                                    width={sizes.width}
                                    height={sizes.height}
                                    quality={sizes.quality}
                                    placeholder="empty"
                                    loading="lazy"
                                    layout="intrinsic"
                                    //blurDataURL={config.avatar.blurdataurl}
                                    className={` object-cover rounded-full overflow-hidden flex-none  bg-gray-200 `}
                                    // style={
                                    //     isPlaying 
                                    //       ? {
                                    //           backgroundImage: `url("/images/app/live-64x64.gif")`
                                    //           , backgroundRepeat: `no-repeat`
                                    //           ,backgroundSize: `cover`
                                    //           ,backgroundPosition: `center`
                                    //         }
                                    //       : {}
                                    //   }
                                    />
                                </div>

                                {isPlaying &&
                                <div 
                                className={`${rotaryContainerClass} flex items-center content-center absolute 
                                `}>
                                  <LoadingRotary 
                                    classSize={rotaryInternalClass}
                                    classAnimate=''
                                    bgColor='transparent'
                                    centerColor='transparent'
                                    pointerColor={showReminder 
                                                  ? "#ffffff"
                                                  :isContributor 
                                                    ? '#ff0000'
                                                    : 'transparent' }
                                    holeColor='transparent'
                                    dialerColor='transparent'
                                    size='64'
                                  />
                                </div> 
                                }
                              </div>
    </>)
}

export default AvatarFlairClean